<template>
    <div class="background-1 page-height">
        <v-container class="page-content-offset title-background page-height pt-0 car-detail-container" >
            <div class="row">
                <div class="col-12 col-xs-12 col-md-6 car-detail-image-padding">
                    <div class="row">
                        <div class="col-12 car-image" :style="'background-image:url('+ img + ')'">  
                        </div>
                    </div>
                    <div class="row car-details-title">
                        <div class="col-12 pb-0">
                            <span class="title-font">{{item.brand}} {{item.model}}</span>
                        </div>
                        <div class="col-4 col-xs-4 col-md-3 col-sm-4">
                            <div class="car-plate car-plate-detail">
                            <b>{{item.registrationNo}}</b></div>
                        </div>
                        <div class="col-8 col-xs-8 col-md-9 col-sm-8 d-none">
                            <span>Warehouse Lot: </span><span class="parking-lot">{{parkingLotNo}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xs-12 col-md-6">
                    <div class="row">
                        <div class="col-12" style="padding:0px">
                            <v-tabs fixed-tabs dark hideSlider>
                                <v-tab class="tab-title" active-class="tab-title-active">Information</v-tab>
                                <v-tab-item :disabled="true">
                                    <MyCarDetailsInformation :item="item"></MyCarDetailsInformation>
                                </v-tab-item>
                                <v-tab class="tab-title" active-class="tab-title-active">Activities</v-tab>
                                <v-tab-item :disabled="true">
                                    <MyCarDetailsActivities :item="item"></MyCarDetailsActivities>
                                </v-tab-item>
                                <v-tab class="tab-title" active-class="tab-title-active">Documents</v-tab>
                                <v-tab-item :disabled="true">
                                    <MyCarDetailsDocument :item="item"></MyCarDetailsDocument>
                                </v-tab-item>
                            </v-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import MyCarDetailsInformation from './MyCarDetailsInformation.vue';
import MyCarDetailsActivities from './MyCarDetailsActivities.vue';
import MyCarDetailsDocument from './MyCarDetailsDocument.vue';
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, uploadPath, uploadTypeCar } from "/src/constants/config";

export default {
    mixins: [currentUserMixin],

    data() {
		return {
            item: {},
            parkingLotNo: "",
            img: "",
		}
    },

    components: {
        MyCarDetailsInformation,
        MyCarDetailsActivities,
        MyCarDetailsDocument
    }, 
  
    mounted() {		
        this.getCarDetail();		
    }, 

    methods: {
        getCarDetail(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            let id =params.get("id");
            let url = openRoadApiUrl + "/api/services/" + version + "/car/me/view/" + id;
            axios.get(url, this.$data.apiHeaders)
                .then(response => this.setCarInformation(response))
                .catch(error => this.onError(error));
        },

        setCarInformation(response) {
            this.item = response.data.result;
            this.parkingLotNo = response.data.result.parkingLot ? response.data.result.parkingLot.parkingLotNo : "";
            this.img = response.data.result.carPhotos.length > 0
                       ? uploadPath + "/" + uploadTypeCar + "/" + response.data.result.carPhotos[0].fileName
                       : "";
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        }
    },

    computed: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
