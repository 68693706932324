<template>
    <div>
        <v-card color=black tile>                            
            <v-card-text>
                <div class="row">
                    <div class="col-2 pb-0 pl-0 col-md-1 col-sm-1">
                        <v-badge bottom overlap class="check-mark" left icon="mdi-checkbox-marked-circle" offset-x="18" offset-y="18">
                            <template v-slot:badge>
                                   <span><i :class="statusCss" aria-hidden="true"></i></span>
                            </template>
                            <div>
                                <img class="activity-icon" :src="activity.activityCategoryIcon">
                            </div>
                        </v-badge>
                    </div>
                    <div class="col-10 col-md-11 col-sm-11" @click="toggleShowDetail">
                        <div class="row">
                            <div class="col-10 col-md-11 col-sm-11 pb-0">
                                <div class="row pt-1">
                                    <div class="col-12 pb-0 pt-0">
                                        <span>{{formattedDateAction}}</span>
                                    </div>
                                    <div class="col-12 pt-0">
                                        <span class="profile-title">{{activity.activityCategoryDescription}}</span>
                                    </div>
                                </div>
                                <div v-if="showDetail">
                                    <div class="row">
                                        <div class="col-3 col-md-1 col-sm-1 pt-0 car-photo" v-for="(photo, index) in activity.photos" :key="photo.id">
                                            <img class="activity-action-photo-client" :src='photoPath + photo.fileName' @click="viewPhoto(index)">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2 col-md-1 col-sm-1">
                                            <img class="staff-pic" :src='staffProfilePicture'>
                                        </div>
                                        <div class="col-10 col-md-11 col-sm-11">
                                            <span>{{activity.userFullName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 col-md-1 col-sm-1 pb-0">
                                <span><i :class="showDetailCss" aria-hidden="true"></i></span>
                            </div>
                        </div>
                        <div class="row pt-4"> 
                            <v-divider class="divider"></v-divider>
                        </div>                        
                    </div>
                </div>                
            </v-card-text>
        </v-card>
        <ViewImage v-if="viewImage" :photos="photos" :selectedImageIndex="selectedImageIndex" @closeViewImage="closeViewImage"></ViewImage>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, uploadPath, uploadTypeActivity} from "/src/constants/config";
import moment from 'moment';
import ViewImage from '/src/views/staff/ViewImage';

export default {
    props: ['activity', 'isCompleted'],

    data() {
		return {
            showDetail: false,
            formattedDateAction: "",
            activityIcon: "",
            viewImage: false,
            photos: [],
            selectedImageIndex: 0,
		}
    },

    components: { ViewImage },
  
    mounted() {	
        this.formatDateAction();   
        this.processPhoto();     
    }, 

    methods: {
        processPhoto(){
            this.activity.photos.forEach(element => {
                this.photos.push(element.fileName);
            });
        },

        formatDateAction(){
            if(this.isCompleted){
                let dateTime = this.activity.dateComplete;
                this.formattedDateAction = moment(dateTime).calendar({ sameDay: '[Today,] h:mm A',
                                                                    nextDay: '[Tomorrow,] h:mm A',
                                                                    nextWeek: 'dddd, h:mm A',
                                                                    lastDay: '[Yesterday,] h:mm A',
                                                                    lastWeek: '[Last] dddd, h:mm A',
                                                                    sameElse: 'DD MMM YYYY, h:mm A'
                                                                })       
            }
            else{
                let dateTime = this.activity.dateAction;
                this.formattedDateAction = moment(dateTime).calendar({ sameDay: '[Today]',
                                                                    nextDay: '[Tomorrow]',
                                                                    nextWeek: 'dddd',
                                                                    lastDay: '[Yesterday]',
                                                                    lastWeek: '[Last] dddd',
                                                                    sameElse: 'DD MMM YYYY'
                                                                })       
            }                
        },        

        toggleShowDetail(){
            this.showDetail = !this.showDetail;
        },

        viewPhoto(index){
            this.selectedImageIndex = index;
            this.viewImage = true;
        },

        closeViewImage(){
            this.viewImage = false;
        },
    },

    computed: {
        showDetailCss(){
            return this.showDetail ? "fa fa-caret-up" : "fa fa-caret-down";
        },
        statusCss(){
            return this.activity.status == "Completed" ? "fa fa-check-circle check-mark-completed" : "";
        },
        staffProfilePicture(){
            return this.activity.staffPic ? this.activity.staffPic :  require('/src/assets/img/profile_pic.png');
        },
        photoPath(){
            return uploadPath + "/" + uploadTypeActivity + "/";
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>