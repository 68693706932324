<template>
    <div>
        <v-card color=black tile class="my-cars-detail-container">                            
            <v-card-text class="pt-0">                
                <div v-for="(value, propertyName, index) in documentsGroupByType" :key="index">
                    <div class="row pt-8 pb-0">
                        <div class="col-12 pb-0">
                            <span class="my-car-information-title-1">{{propertyName}}</span>
                        </div>
                    </div>  
                    <div class="my-cars-detail-bottom-spacing">          
                        <div class="row pt-0 pb-0" v-for="item in value" :key="item.id">
                            <div class="col-10 col-md-10 col-sm-11 pb-0">
                                <span class="my-car-information-data">{{item.name}}</span>
                            </div>
                            <div class="col-2 col-md-2 pb-0 col-sm-1 pl-auto document-pdf">
                                <a :href="filepath + item.fileName" target="_blank" >
                                    <img src='../assets/img/pdf-file.png'>
                                </a>
                            </div>
                            <div class="col-12 pb-0 pt-0">
                                <v-divider class="divider"></v-divider>
                            </div>
                        </div>      
                    </div>              
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, uploadPath } from "/src/constants/config";
import _ from 'lodash';

export default {
    mixins: [currentUserMixin],

    props: ['item'],

    data() {
		return {
            documents: [],
            documentsGroupByType: [],
            filepath: "",
		}
    },

    components: {
        
    },
  
    mounted() {		
        this.filepath = uploadPath + "/cardocument/"
        this.getCarDocuments();
    }, 

    methods: {
        getCarDocuments(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            let id =params.get("id");

            let url = openRoadApiUrl + "/api/services/" + version + "/cardocument/me/list?CarId=" + id;
            axios.get(url, this.$data.apiHeaders)
                .then(response => this.setCarDocuments(response))
                .catch(error => this.onError(error));
        },

        setCarDocuments(response) {
            this.documents = response.data.result.items.map(function(data, key){
            let obj = {};
            obj['name'] = data.name;
            obj['fileName'] = data.fileName;
            obj['documentType'] = _.startCase(data.documentType);
            obj['id'] = data.id;

            return obj;
            });

            this.documentsGroupByType = _.groupBy(this.documents, function(document) {
                return document.documentType;
            });            
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        }
    },

    computed: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>