<template>
    <div>
        <v-card color=black tile class="my-cars-detail-container">                            
            <v-card-text>
                <div class="row">
                    <div class="col-12 pb-0">
                        <span class="my-car-information-title-1">General</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <span class="my-car-information-title">Parked since</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.dateParked | formatDate}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Location</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.autoStorageName | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Last checked-in</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{lastCheckinMileage}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Last checked-out</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{lastCheckoutMileage}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Insurance expiry</span>
                    </div>
                    <div class="col-6">
                        <span class="my-car-information-data">{{this.item.dateInsuranceExpiry | formatDate}} </span>
                      <span class="my-car-information-data" v-if="isInsuranceExpired || isInsuranceExpiring">({{ numberDaysInsurance }} days)</span>
                    </div>
                    <div class="col-2 my-car-information-position" v-if="isInsuranceExpiring||isInsuranceExpired">
                        <img class="my-car-information-warning" src="../assets/img/warning.png" :title="insuranceWarningText" />
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Road Tax expiry</span>
                    </div>
                    <div class="col-6">
                        <span class="my-car-information-data">{{this.item.dateRoadTaxExpiry | formatDate}} </span>
                      <span class="my-car-information-data" v-if="isRoadTaxExpiring || isRoadTaxExpired">({{ numberDaysRoadTax }} days)</span>
                    </div>
                    <div class="col-2 my-car-information-position" v-if="isRoadTaxExpiring || isRoadTaxExpired">
                        <img class="my-car-information-warning" src="../assets/img/warning.png" :title="roadTaxWarningText" />
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-5">
                    <div class="col-12" style="padding-bottom: 0px">
                        <span class="my-car-information-title-1">My Car Details</span>
                    </div>
                </div>                
                <div class="row">
                    <div class="col-4">
                        <span class="my-car-information-title">Make</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.brand | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Model</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.model | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Engine Number</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.engineNo | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Chassis Number</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.chassisNo | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Year Make</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.yearMake | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                 <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Colour</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.colour | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4" style="padding-right: 0px;">
                        <span class="my-car-information-title">Registration Date</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{this.item.dateRegistration | formatDate}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>




                <div class="row mt-5">
                    <div class="col-12" style="padding-bottom: 0px">
                        <span class="my-car-information-title-1">Bank Guarantee</span>
                    </div>
                </div>                
                <div class="row">
                    <div class="col-4">
                        <span class="my-car-information-title">Start Date</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{bankGuranteeStartDate | formatDate}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">End Date</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{bankGuranteeEndDate | formatDate}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Amount</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{bankGuranteeAmount | formatAmount}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
                 <div class="row mt-1">
                    <div class="col-4">
                        <span class="my-car-information-title">Bank</span>
                    </div>
                    <div class="col-8">
                        <span class="my-car-information-data">{{bankGuranteeBank | formatData}}</span>
                    </div>
                </div>
                <v-divider class="divider"></v-divider>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['item'],

    data() {
		return {
            lastCheckinMileage : 0,
            lastCheckoutMileage : 0,

            isInsuranceExpiring: false,
            isRoadTaxExpiring: false,
            isInsuranceExpired: false,
            isRoadTaxExpired: false,

            numberDaysInsurance: 0,
            numberDaysRoadTax: 0,

            bankGuranteeStartDate: "",
            bankGuranteeEndDate: "",
            bankGuranteeAmount: "",
            bankGuranteeBank: "",
		}
    },

    components: {
        
    },
  
    mounted() {		
    }, 

    methods: {
        updateAttributes(){
            this.lastCheckinMileage = this.item.lastCheckinMileage ? this.item.lastCheckinMileage + " KM": "-";
            this.lastCheckoutMileage = this.item.lastCheckoutMileage ? this.item.lastCheckoutMileage + " KM": "-"; 
            
            if(this.item.dateRoadTaxExpiry){
                this.numberDaysRoadTax = this.getDaysDifference(this.item.dateRoadTaxExpiry);
                this.isRoadTaxExpiring = this.numberDaysRoadTax >= 0 && this.numberDaysRoadTax <= 30;
                this.isRoadTaxExpired = this.numberDaysRoadTax < 0;
            }

            if(this.item.dateInsuranceExpiry){
                this.numberDaysInsurance = this.getDaysDifference(this.item.dateInsuranceExpiry);
                this.isInsuranceExpiring = this.numberDaysInsurance >= 0 && this.numberDaysInsurance <= 30;
                this.isInsuranceExpired = this.numberDaysInsurance < 0;
            }

          this.bankGuranteeStartDate = this.item.bankGuaranteeDateEffectiveStart;
          this.bankGuranteeEndDate = this.item.bankGuaranteeDateEffectiveEnd;
          this.bankGuranteeAmount = this.item.bankGuaranteeAmount;
          this.bankGuranteeBank = this.item.bankGuaranteeFinanceCompany;
        },

        getDaysDifference(date){
            let start = moment(date).startOf('day');
            let end = moment().startOf('day');
            let days = start.diff(end, 'days'); 
            return days;
        }
    },

    computed: {
        roadTaxWarningText(){
            return this.isRoadTaxExpired 
                    ? "Expired"
                    : this.isRoadTaxExpiring 
                        ? "Expiring soon"
                        : "";
        },

        insuranceWarningText(){
            return this.isInsuranceExpired 
                    ? "Expired"
                    : this.isInsuranceExpiring 
                        ? "Expiring soon"
                        : "";
        }
    },

    watch:{
        item(){
            this.updateAttributes();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>