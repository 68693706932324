<template>
    <div>
        <v-card color=black tile  :class="containerCss">                            
            <v-card-text>
                <div class="row pt-0">
                    <div class="col-12 col-md-12 activity-button-position">
                        <v-btn outlined :class="completedButtonCss" @click="getCompletedActivities"> Completed </v-btn>
                        <v-btn outlined :class="upcomingButtonCss" @click="getUpcomingActivities"> Upcoming </v-btn>
                        <v-btn outlined class="activity-button" active-class="activity-button-active"  @click="toggleFilters"> Filter
                            <img class="pl-2" :src="require('/src/assets/img/filter.png')">
                        </v-btn>
                    </div>
                </div>
                <div class="pt-4">
                    <div v-for="activity in activities" :key="activity.id">
                        <MyCarDetailActivityDetails :activity="activity" :isCompleted="isCompleted"></MyCarDetailActivityDetails>
                    </div>
                </div>
            </v-card-text>

            <div :class="filterCss">
            <ActivityFilters v-if="showFilter" @filterActivity="filterActivity" :currentSelectedFilter="currentSelectedFilter"></ActivityFilters>
            </div>
        </v-card>
    </div>
</template>

<script>
import MyCarDetailActivityDetails from './MyCarDetailActivityDetails.vue';
import ActivityFilters from './ActivityFilters.vue';
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, activityCategories, statusPending, statusInProgress, statusCompleted} from "/src/constants/config";
import _ from "lodash";

export default {
    props: ['item'],
     mixins: [currentUserMixin],

    data() {
		return {
            activities: [],
            allActivities: [],
            showFilter: false,
            selectedFilterCategoryId: "All",        
            selectedStatus: "Completed",    
            currentSelectedFilter: "",
            isUpcoming: false,
            isCompleted: true,
		}
    },

    components: {
        MyCarDetailActivityDetails,
        ActivityFilters
    },
  
    mounted() {	
        this.getActivities(statusCompleted);          
    }, 

    methods: {
        getActivities(status){
            this.selectedStatus = status;
            this.page = this.page + 1;
            if(this.item.id){     
                
                axios.get(this.url, this.$data.apiHeaders)
                    .then(response => this.setActivites(response))
                    .catch(error => this.onError(error));
            }    
        },

        setActivites(response) {
            this.allActivities = response.data.result.items.map(function(item){
                let obj = {};

                obj['userId'] = item.userId;
                obj['userFullName'] = item.userName;
                obj['activityCategoryId'] = item.activityCategoryId;
                obj['activityCategoryName'] = item.activityCategoryName;
                obj['activityCategoryDescription'] = item.description ? item.description : "-";
                obj['dateAction'] = item.dateAction;
                obj['dateComplete'] = item.dateComplete;
                obj['status'] = item.status;
                obj['photos'] = item.photos;
                obj['id'] = item.id;
                obj['staffPic'] = item.userPic;

                let icon =  _.find(activityCategories, function(o){ return item.activityCategoryId == o.activityCategoryId });
                obj['activityCategoryIcon'] = icon ? icon.src : require('../assets/img/Others.png');

                return obj;
            });
            
            this.getFilterActivities();
        },

        getCompletedActivities(){
            this.isUpcoming = false;
            this.isCompleted = true;
            this.showFilter = false;
            this.selectedStatus = "Completed";
            this.getActivities(this.selectedStatus);
        },

        getUpcomingActivities(){            
            this.isUpcoming = true;
            this.isCompleted = false;
            this.showFilter = false;
            this.selectedStatus = "Pending";
            this.getActivities(this.selectedStatus);
        },

        toggleFilters(){
            this.showFilter = !this.showFilter;
        },

        filterActivity(item){
            this.currentSelectedFilter = item
            this.selectedFilterCategoryId = item.activityCategoryId;
            this.showFilter = false;

            this.getFilterActivities();           
        },

        getFilterActivities(){
            let activitiesFilterByCategory = this.allActivities;

            if(this.selectedFilterCategoryId == "Others"){
                activitiesFilterByCategory = _.differenceBy(this.allActivities, activityCategories, 'activityCategoryId');
            }
            else if(this.selectedFilterCategoryId != "All"){
                activitiesFilterByCategory = _.filter(this.allActivities, function(o){ return o.activityCategoryId == this.selectedFilterCategoryId }.bind(this));
            } 
            this.activities = activitiesFilterByCategory;            
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        }

    },

    computed: {
        filterCss(){
            return this.showFilter ? "activity-filter-position activity-filter-position-active" : "activity-filter-position";
        },
        url(){
            let url = openRoadApiUrl + "/api/services/" + version + "/activity/me/list?SortDirection=desc&CarId=" + this.item.id + "&Status=" + this.selectedStatus;
            let sort = "dateComplete";

            if(this.selectedStatus == "Pending") {
              sort = "dateAction";
            }

            url += "&Sort=" + sort;

            return url
        },
        upcomingButtonCss(){
            return this.isUpcoming ? "activity-button activity-button-active" : "activity-button";
        },
        completedButtonCss(){
            return this.isCompleted ? "activity-button activity-button-active" : "activity-button";
        },
        containerCss(){
            return this.showFilter ? "my-cars-detail-container my-cars-detail-container-with-filter" : "my-cars-detail-container"
        }
    }, 

    watch: {
        item(){
            this.getActivities(statusCompleted);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>